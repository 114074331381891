import axios from 'axios';
import { ToolItem } from './types';

const TOOL_CSV = `${process.env.REACT_APP_TOOL_URL ?? process.env.PUBLIC_URL}/tool/data.csv`

export const getToolData = async (): Promise<ToolItem[]> => {
    const { data } = await axios.get(`${TOOL_CSV}?t=${Date.now()}`);

    return parseCSV(data);
}

export const parseCSV = (csv: string): ToolItem[] => {
    const rows = csv.split('\n');
    const headers = rows[0].split(',').map((header: string) => header.trim());

    const data: ToolItem[] = rows.slice(1).map((row: string) => {
      const rowData = row.split(',') as (keyof ToolItem)[];

        return headers.reduce((acc, header, index) => {
            return {
                ...acc,
                [header]: rowData[index],
            };
        }, {} as ToolItem);
    });

    return data
}

export const formatNumberAsPercentage = (value: number, locales: string | string[] = 'en-US', maximumFractionDigits: number = 2): string => {
  const formatter = new Intl.NumberFormat(locales, {
      style: 'percent',
      maximumFractionDigits,
  });

  return formatter.format(value);
}