import React from "react"
import { Box } from "@mui/material";
import { IconLinkedIn, IconTwitter, IconYoutube } from "../Landing/assets";
import { Link } from "react-router-dom";

interface Props {
}
class SiteFooter extends React.Component<Props> {

  render() {
    return(
      <Box component={'footer'} sx={{
          zIndex: 501,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          background: '#2F2D2D 0% 0% no-repeat padding-box',
          bottom: 0,
          left: 0,
          position: 'fixed',
          height: '5rem',
          color: '#FAFAFA',
          padding: {
            xs: '6rem 3rem',
            md: '4rem 3rem',
          },
          ['@media print']: {
              display: 'none',
          },
      }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              font: 'normal normal normal 16px/28px Roboto',
              letterSpacing: 0,
              ['@media (max-width: 768px)']: {
                  flexDirection: 'column',
              },
            }}>
          <Box>
                ©2024. All rights reserved.
            </Box>
            <Box sx={{
                '& a': {
                  font: 'normal normal normal 16px/28px Roboto',
                  letterSpacing: '0px',
                },
                '& a:hover': {
                  color: '#FAFAFA',
                  opacity: 0.5,
                  textDecoration: 'none',
                },
            }}>
              <Box component={Link} to={'/'} sx={{ mr: '1rem', color: 'unset'}}>
                  Book
              </Box>
              <Box component={Link} to={'/tool'} sx={{ ml: '1rem', mr: '1rem', color: 'unset'}}>
                Option Screener
              </Box>
              <Box component={Link} to={'/authors'} sx={{ ml: '1rem', mr: '1rem', color: 'unset' }}>
                  Authors
              </Box>
            </Box>
            <Box>
              <a href={'https://www.linkedin.com/'} target="_blank" rel="noopener noreferrer">
                <IconLinkedIn sx={{ height: '2rem', width: '2rem' }} />
              </a>
              <a href={'https://www.twitter.com/'} target="_blank" rel="noopener noreferrer">
                <IconTwitter sx={{ height: '2rem', width: '2rem', ml: '4rem' }} />
              </a>
              <a href={'https://www.youtube.com/'} target="_blank" rel="noopener noreferrer">
                <IconYoutube sx={{ height: '3rem', width: '3rem', ml: '4rem' }} />
              </a>
            </Box>
        </Box>
      </Box>
    )
  }
}

export default SiteFooter
