import { FunctionComponent } from 'react';
import { BaseProps } from '../../types';
import { Box } from '@mui/material';
import StyledListItem from './StyledListItem';

interface Props extends BaseProps {
  headLine: string;
  subHeadLine: string;
}
const HomeListItem: FunctionComponent<Props> = ({ sx, headLine, subHeadLine, children }: Props) => {
    return (
      <StyledListItem sx={sx}>
      <Box
          sx={{
              textAlign: 'left',
              letterSpacing: '0px',
              color: '#2F2D2D',
              opacity: '1'
          }}>
          {headLine && <Box component={'span'} sx={{ font: 'normal normal bold 18px/24px Roboto' }}>{headLine}</Box>}
          {subHeadLine && <Box component={'span'} sx={{ font: 'normal normal normal 18px/24px Roboto' }}>{subHeadLine}</Box>}
          {children}
      </Box>
  </StyledListItem>
    )
}

export default HomeListItem