import React from 'react';
import Page from '../common/Page';
import { BaseProps } from '../../types';
import { BgAuthors, BgFooterEdgeGrey, DavidBernsBio, MichaelGreenBio } from '../assets';
import { Box, Link } from '@mui/material';
import BookCallToAction from '../common/BookCallToAction';
import Bio from './Bio';

class Authors extends React.Component <BaseProps> {
    render() {
        return (
            <Page>
                <Box sx={{ display: 'flex', position: 'relative', zIndex: 5}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
                        <BgAuthors sx={{ width: '100%', minHeight: '100rem', objectFit: 'cover', zIndex: '5' }} />
                        <Box sx={{ display: 'flex', background: '#2F2D2D', height: '100%' }} />
                        <BgFooterEdgeGrey  />
                    </Box>
                    <Box sx={{ mt: '15rem', zIndex: 5, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pl: { xs: '4rem', md: 'unset'}, pr: { xs: '4rem', md: 'unset'}  }}>
                        <Box sx={{
                            textAlign: 'center',
                            font: 'normal normal normal 48px/52px PT Serif',
                            letterSpacing: '0px',
                            color: '#FAFAFA',
                            opacity: '1'
                        }}>About The Authors</Box>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, mt: '10rem', pb: '25rem' }}>
                            <Bio 
                                avatar={<DavidBernsBio />}
                                name={'David Berns, PhD'}
                                title={<span>CIO & COFOUNDER OF <Link href='https://simplify.us' sx={{ color: 'white', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">SIMPLIFY ASSET MANAGEMENT</Link></span>}>
                                    <Box sx={{ mb: '2rem' }}>David has committed his entire career to developing quantitative solutions for the advisory community, with a heavy focus on asset allocation and risk management across liquid and illiquid asset classes. He began his finance career at a $5 billion multi-family office where he developed cutting-edge asset allocation, portfolio management, and risk management systems for managing private and institutional wealth. David then pivoted to developing short- and intermediate-term investment strategies that help improve both return and risk characteristics of a strategic asset allocation. Prior to founding Simplify, David created Portfolio Designer, a cloud-based asset allocation platform helping advisors build customized portfolios with cutting-edge behavioral and statistical techniques.</Box>
                                    <Box>David has a PhD in Physics from the Massachusetts Institute of Technology in the field of Quantum Computation and is the author of "Modern Asset Allocation for Wealth Management” published by Wiley. He currently lives in New York City with his wife Carolee and son Henry.</Box>
                            </Bio>
                            <Bio 
                                avatar={<MichaelGreenBio />}
                                name={'Michael Green, CFA'}
                                title={<span>PORTFOLIO MANAGER, CHIEF STRATEGIST OF <Link href='https://simplify.us' sx={{ color: 'white', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">SIMPLIFY ASSET MANAGEMENT</Link></span>}
                                sx={{ ml: { xs: 0, md: '5rem'}, mt: { xs: '10rem', md: 0 } }}>
                                    Michael has been a student of markets and market structure, for nearly 30 years. His proprietary research into the shift from actively managed portfolios and investment funds to systematic passive investment strategies has been presented to the Federal Reserve, the BIS, the IMF and numerous other industry groups and associations. Michael joined Simplify in April 2021 after serving as Chief Strategist and Portfolio Manager for Logica Capital Advisers, LLC. Prior to Logica, Michael managed macro strategies at Thiel Macro, LLC, an investment firm that manages the personal capital of Peter Thiel. Prior to Thiel, Michael founded Ice Farm Capital, a discretionary global macro hedge fund seeded by Soros Fund Management. From 2006-2014, Michael founded and managed the New York office of Canyon Capital Advisors, a $23B multi-strategy hedge fund based in Los Angeles, CA, where he established their global macro strategies, managing in excess of $5B of exposure across equity, credit, FX, commodity and derivative markets.
                            </Bio>
                        </Box>
                    </Box>
                </Box>
                <BookCallToAction sx={{ mt: '-18rem', pt: '18rem', zIndex: 4 }}/>
            </Page>
        )
    }
}

export default Authors