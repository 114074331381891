import React from 'react';
import { Box, BoxProps } from '@mui/material';

import bookLogo from './logo-trading-options.svg';
import bookLogoWhite from './logo-trading-options-white.svg';
import homeBackground from './bg-homepage.jpg';
import iconTwitter from './icon-twitter.svg';
import iconLinkedIn from './icon-linkedin.svg';
import iconYoutube from './icon-youtube.svg';
import logoAmazon from './logo-amazon.png';
import logoBarnes from './logo-barnes-noble.png';
import logoGoodreads from './logo-goodreads.png';
import bookCover from './cover-image-with-cta.png';
import squareLines from './bg-line-graphics.svg';
import bulletStyle from './bullet-style.svg';
import bgFooterEdge from './bg-footer-edge.png';
import bgFotterEdgeGrey from './bg-footer-edge-grey.png';
import iconReviewStart from './icon-review-star.svg';
import bgFooterCTA from './bg-footer-cta.jpg';
import bookCoverNoCTA from './cover-image-no-cta.png';
import bgAuthors from './bg-authors.jpg';
import davidBernsBio from './david-berns-bio.png';
import michaelGreenBio from './michael-green-bio.png';
import bgOptionsTool from './bg-options-tool.jpg';
import ctaUseOptionScreener from './cta-use-option-screener.svg';

export interface AssetProps extends React.HTMLAttributes<HTMLImageElement> {
    sx?: BoxProps['sx']
}
export interface LinesProps extends AssetProps {
    containerSx?: BoxProps['sx']
}

export const BookLogo = (props: AssetProps) => <Box component={'img'} src={bookLogo} alt="Book Logo" {...props} />;
export const BookLogoWhite = (props: AssetProps) => <Box component={'img'} src={bookLogoWhite} alt="Book Logo" {...props} />;
export const HomeBackground = (props: AssetProps) => <Box component={'img'} src={homeBackground} alt="Home Background" {...props} />;
export const IconTwitter = (props: AssetProps) => <Box component={'img'} src={iconTwitter} alt="Twitter" {...props} />;
export const IconLinkedIn = (props: AssetProps) => <Box component={'img'} src={iconLinkedIn} alt="LinkedIn" {...props} />;
export const IconYoutube = (props: AssetProps) => <Box component={'img'} src={iconYoutube} alt="Youtube" {...props} />;

export const LogoAmazon = (props: AssetProps) => <Box component={'img'} src={logoAmazon} alt="Amazon" {...props} />;
export const LogoBarnes = (props: AssetProps) => <Box component={'img'} src={logoBarnes} alt="Barnes&Noble" {...props} />;
export const LogoGoodreads = (props: AssetProps) => <Box component={'img'} src={logoGoodreads} alt="Goodreads" {...props} />;
export const BookCover = (props: AssetProps) => <Box component={'img'} src={bookCover} alt="Book Cover" {...props} />;
export const LeftSquareLines = (props: LinesProps) => <Box sx={{ display: 'flex', overflow: 'hidden', width: '40rem', ...(props.containerSx ?? {}) }}><Box component={'img'} src={squareLines} alt="Decorative Lines" {...props} sx={{ ...(props.sx ?? {}), transform: 'translate(-50%, 0)' }} /></Box>;
export const RightSquareLines = (props: LinesProps) => <Box sx={{ display: 'flex', overflow: 'hidden', width: '40rem', transform: 'scaleX(-1)', ...(props.containerSx ?? {}) }}><Box component={'img'} src={squareLines} alt="Decorative Lines" {...props}  sx={{ ...(props.sx ?? {}), transform: 'translate(-50%, 0)' }} /></Box>;
export const BulletStyle = (props: AssetProps) => <Box component={'img'} src={bulletStyle} alt="Bullet" {...props} />;

export const BgFooterEdge = (props: AssetProps) => <Box component={'img'} src={bgFooterEdge} alt="Footer Edge" {...props} />;
export const BgFooterEdgeGrey = (props: AssetProps) => <Box component={'img'} src={bgFotterEdgeGrey} alt="Footer Edge Grey" {...props} />;
export const IconReviewStar = (props: AssetProps) => <Box component={'img'} src={iconReviewStart} alt="Review Star" {...props} />;
export const BgFooterCTA = (props: AssetProps) => <Box component={'img'} src={bgFooterCTA} alt="Footer CTA" {...props} />;

export const BookCoverNoCTA = (props: AssetProps) => <Box component={'img'} src={bookCoverNoCTA} alt="Book Cover" {...props} />;
export const BgAuthors = (props: AssetProps) => <Box component={'img'} src={bgAuthors} alt="Authors" {...props} />;

export const DavidBernsBio = (props: AssetProps) => <Box component={'img'} src={davidBernsBio} alt="David Berns" {...props} />;
export const MichaelGreenBio = (props: AssetProps) => <Box component={'img'} src={michaelGreenBio} alt="Michael Green" {...props} />;
export const BgOptionsTool = (props: AssetProps) => <Box component={'img'} src={bgOptionsTool} alt="Options Tool" {...props} />;
export const UseOptionScreener = (props: AssetProps) => <Box component={'img'} src={ctaUseOptionScreener} alt="Use Option Screener" {...props} />;

export {
    bookLogo,
    homeBackground,
};
