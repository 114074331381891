import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import NavBar from './NavBar/NavBar';
import SiteFooter from './SiteFooter';
import { Home } from './Landing/Home';
import JsonLD from "./Landing/common/JsonLD";
import SocialMetaTags from "./Landing/common/SocialMetaTags";
import { Authors } from "./Landing/Authors";
import { Tool } from "./Landing/Tool";

const App = () => {
  return (
    <Router>
        <JsonLD />
        <SocialMetaTags />
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/authors" element={<Authors />} />
          <Route path="/tool" element={<Tool />} />
        </Routes>
        <SiteFooter />
      </Router> 
  )
}

export default App