import { Box, TableCell, TableRow } from '@mui/material';
import { BaseProps } from '../../../components/types';
import { FunctionComponent } from 'react';
import { ToolItem } from '../../../api/types';

interface Props extends BaseProps {
    item: ToolItem;
    index: number;
    highlight?: boolean;
}

const OptionsRow: FunctionComponent<Props> = ({ sx, index, item, highlight }: Props) => {
  const rowColor = index % 2 === 0 ? 'rgba(216, 216, 210, .32)' : 'rgba(216, 216, 210, 1)';
  const backgroundColor = highlight || item.highlight ? '#F5D579' : rowColor;
  return (
    <TableRow key={item.Names} sx={{
        backgroundColor,
        '&:hover': {
            backgroundColor: 'white',
        },
        boxShadow: highlight || item.highlight ? '0px 6px 6px rgba(0, 0, 0, 0.16)' : undefined,
        mixBlendMode: highlight || item.highlight ?  'multiply' : undefined,
        '& td, & th': {
            fontWeight: highlight || item.highlight ? 'bold' : undefined
        },
        ...(sx ?? {}),
        }}>
        <TableCell component="th" scope="row">
          <Box sx={{ display: 'flex' }}>
            <Box component={'span'} sx={{ fontWeight: 'bold', mr: '2rem'}}>{index + 1}</Box>
            <Box>{item.Names}</Box>
          </Box>
        </TableCell>
        <TableCell>{!isNaN(item.current_premium) ? Number(item.expected_return).toFixed(1) : ''}</TableCell>
        <TableCell>{!isNaN(item.current_premium) ? Number(item.current_premium).toFixed(1) : ''}</TableCell>
        <TableCell>{!isNaN(item.current_premium) ? Number(item.slippage_ratio).toFixed(1) : ''}</TableCell>
        <TableCell>{!isNaN(item.current_premium) ? Number(item.prob_win).toFixed(1) : ''}</TableCell>
        <TableCell>{!isNaN(item.current_premium) ? Number(item.mean_loss_pl).toFixed(1) : ''}</TableCell>
        <TableCell>{!isNaN(item.current_premium) ? Number(item.moneyness_width).toFixed(1) : ''}</TableCell>
        <TableCell>{item.contract ? item.contract : ''}</TableCell>
    </TableRow>
  );
}

export default OptionsRow
