import React, { FunctionComponent } from 'react';
import { BaseProps } from '../../../components/types';
import { Box } from '@mui/material';
import { LogoAmazon, LogoBarnes, LogoGoodreads } from '../assets';

interface Props extends BaseProps {}
const AvailableForPreorder: FunctionComponent<Props> = ({ sx }: Props) => {
    return (
        <Box sx={{ ...(sx ?? {})}}>
            <Box sx={{
                textAlign: 'left',
                font: 'normal normal bold 18px/28px Roboto',
                letterSpacing: '0px',
                color: '#312F30',
                textTransform: 'uppercase',
                opacity: '1'
            }}>
                AVAILABLE FOR PREORDER AT:
            </Box>
            <Box sx={{ display: 'flex', mt: '2rem', justifyContent: 'space-between' }}>
              <a href={'https://www.amazon.com/Little-Book-Trading-Options-Like/dp/1394238959/ref=sr_1_1?cri[…]qid=1708707027&sprefix=berns+green+trading%2Caps%2C379&sr=8-1'} target="_blank" rel="noopener noreferrer">
                <LogoAmazon sx={{ height: '4rem' }} />
              </a>
              <a href={'https://www.barnesandnoble.com/w/the-little-book-of-trading-options-like-the-pros-david-m-berns/1144633391?ean=9781394238958'} target="_blank" rel="noopener noreferrer">
                <LogoBarnes sx={{ height: '4rem' }} />
              </a>
              <a href={'https://www.goodreads.com/book/show/198701278-the-little-book-of-trading-options-like-the-pros'} target="_blank" rel="noopener noreferrer">
                <LogoGoodreads sx={{ height: '4rem' }} />
              </a>
            </Box>
        </Box>
    )
}

export default AvailableForPreorder