import { FunctionComponent } from 'react';
import { BaseProps } from '../../types';
import { ListItem, ListItemAvatar } from '@mui/material';
import { BulletStyle } from '../assets';

interface Props extends BaseProps {}
const StyledListItem: FunctionComponent<Props> = ({ sx, children }: Props) => {
    return (
      <ListItem sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '1rem 0',
          ...sx
      }}>
        <ListItemAvatar sx={{ minWidth: '2.75rem', marginTop: '.25rem' }}>
            <BulletStyle />
        </ListItemAvatar>
        {children}
      </ListItem>
    )
}

export default StyledListItem