import * as React from 'react';
import { connect } from "react-redux"

import { BaseProps } from '../types';
import { Box } from '@mui/material';

export interface PageContainerProps extends BaseProps {
    navbarHeight?: number
    innerRef?: any
}

class PageContainer extends React.Component <PageContainerProps> {

    render() {
        const { children = [], navbarHeight = 50, sx = { }, innerRef} = this.props

        return (
            <Box
                ref={innerRef}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: `${navbarHeight || 0}px`,
                    ...sx
            }}>
                {children}
            </Box>
        )
    }
}

export default PageContainer