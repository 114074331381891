import React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseProps } from '../../types';

interface BioProps extends BaseProps {
    avatar: React.ReactNode
    name: string
    title: React.ReactNode
}

class Bio extends React.Component <BioProps> {

    render() {
        const { avatar, name, title, children, sx } = this.props

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '52rem',
                ...sx,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{
                        '& img': {
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                        }
                    }}>
                        {avatar}
                    </Box>
                </Box>
                <Box sx={{
                    marginTop: '2rem',
                    textAlign: 'left',
                    font: 'normal normal normal 28px/44px PT Serif',
                    letterSpacing: '0px',
                    color: '#FAFAFA',
                    opacity: '1'
                }}>
                    {name}
                </Box>
                <Box sx={{
                    marginTop: '2rem',
                    textAlign: 'left',
                    font: 'normal normal normal 18px/24px Roboto',
                    letterSpacing: '0px',
                    color: '#FAFAFA',
                    opacity: '1'
                }}>
                    {title}
                </Box>
                <Box sx={{
                        marginTop: '2rem',
                        textAlign: 'left',
                        font: 'normal normal normal 18px/24px Roboto',
                        letterSpacing: '0px',
                        color: '#FAFAFA',
                        opacity: '1'
                    }}>{children}</Box>
            </Box>
        )
    }
}

export default Bio