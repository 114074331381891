import React, { FunctionComponent } from 'react';
import { BaseProps } from '../../types';
import { Box, List, ListItemAvatar } from '@mui/material';
import { IconReviewStar } from '../assets';

interface Props extends BaseProps {
  maxStars: number;
  rating: number;
}
const ReviewStars: FunctionComponent<Props> = ({ sx, maxStars, rating }: Props) => {
  const starArray = React.useMemo(() => {
    return [...Array(Math.min(maxStars, rating))].map((_, index) => {
      const star = index + 1;
      return (
        <ListItemAvatar key={star} sx={{ minWidth: '2rem', height: '2rem' }}>
          <IconReviewStar sx={{ height: '2rem', width: '2rem', mr: '.5rem' }} />
        </ListItemAvatar>
      )
    });
  }, [maxStars, rating]);
    return (
      <List sx={{ display: 'flex', flexDirection: 'row' }}>
        {starArray}
      </List>
    )
}

export default ReviewStars