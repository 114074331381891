import { FunctionComponent } from 'react';
import { BaseProps } from '../../types';
import { Box, SxProps, Theme } from '@mui/material';
import ReviewStars from './ReviewStars';

interface Props extends BaseProps {
  review: string;
  reviewer: string;
  rating?: number;
  reviewerTitle?: string;
  quoteSx?: SxProps<Theme>;
}
const ReviewItem: FunctionComponent<Props> = ({ sx, quoteSx, rating, review, reviewer, reviewerTitle }: Props) => {
    return (
      <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: 'white',
          padding: '3rem 3rem',
          maxWidth: '40rem',
          ...(sx || {})
      }}>
        {rating && <ReviewStars maxStars={5} rating={rating} />}
        <Box sx={{
          textAlign: 'left',
          mt: '1rem',
          font: 'italic normal normal 20px/28px PT Serif',
          letterSpacing: '0px',
          color: '#2F2D2D',
          opacity: '1',
          ...(quoteSx || {})
        }}>
          "{review}"
        </Box>
        <Box sx={{ mt: '2rem' }}>
          <Box 
            component={'span'} 
              sx={{
                textAlign: 'left',
                font: 'normal normal bold 20px/28px Roboto',
                letterSpacing: '0px',
                color: '#312F30',
            }}>-{reviewer}</Box>
            {reviewerTitle && 
            <Box 
              component={'span'} 
                sx={{
                  textAlign: 'left',
                  font: 'normal normal normal 20px/28px Roboto',
                  letterSpacing: '0px',
                  color: '#312F30',
              }}>, {reviewerTitle}</Box>
            }
        </Box>
      </Box>
    )
}

export default ReviewItem