import React from 'react';
import Page from '../common/Page';
import { BaseProps } from '../../types';
import { BgFooterEdge, BgFooterEdgeGrey, BookCover, BookCoverNoCTA, HomeBackground, LeftSquareLines, RightSquareLines, UseOptionScreener } from '../assets';
import { Box, List } from '@mui/material';
import AvailableForPreorder from '../common/AvailableForPreorder';
import HomeListItem from './HomeListItem';
import ReviewItem from './ReviewItem';
import BookCallToAction from '../common/BookCallToAction';
import { NavLink } from 'react-router-dom';

class Home extends React.Component <BaseProps> {
    render() {
        return (
            <Page>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
                        <HomeBackground sx={{ width: '100%', minHeight: { xs: '100rem', md: '85rem' }, objectFit: 'cover'}} />
                    </Box>
                    <Box sx={{ marginTop: { xs: '5rem', md: '15rem' }, flexDirection: { xs: 'column', md: 'row'}, display: 'flex', padding: '5rem', width: '100%', zIndex: 5 }}>
                       <Box sx={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <Box
                                sx={{
                                    textAlign: 'left',
                                    font: {
                                        xs: 'normal normal normal 40px/44px PT Serif',
                                        md: 'normal normal normal 48px/52px PT Serif'
                                    },
                                    letterSpacing: '0px',
                                    color: '#2F2D2D',
                                    opacity: 1,
                                    maxWidth: '50rem',
                                    marginTop: '3rem'
                                }}>
                                    Master profitable option trading with the techniques of the pros.
                            </Box>
                            <Box
                                sx={{
                                    textAlign: 'left',
                                    font: 'normal normal normal 18px/24px Roboto',
                                    letterSpacing: '0px',
                                    color: '#2F2D2D',
                                    opacity: 1,
                                    marginTop: '2rem',
                                    maxWidth: '51rem',
                                    fontStyle: 'italic',
                                }}>
                                    In The Little Book of Trading Options Like the Pros, a team of veteran options and derivatives traders delivers an expert account of how to master the zero-sum game of options trading.
                            </Box>
                            <AvailableForPreorder sx={{ marginTop: '2rem', width: '100%' }} />
                       </Box>
                       <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', flex: .8 }}>
                            <Box sx={{ height: '45rem', width: '50rem', position: 'relative'}}>
                                <Box sx={{ position: 'absolute', bottom: '15rem', left: '7rem' }}>
                                    <NavLink to={'/tool'}>
                                        <UseOptionScreener sx={{ width: '10rem', height: '10rem' }} />
                                    </NavLink>
                                </Box>
                                <BookCoverNoCTA sx={{ width: '50rem', height: '45rem', maxWidth: { xs: '50rem', md: undefined }, mt: { xs: '2rem', md: 0 } }} />
                            </Box>
                       </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', marginTop: { xs: '10rem', md: '30rem' }, padding: { xs: '0rem 4rem', md: 'unset'}, marginBottom: { xs: '10rem', md: undefined }, position: 'relative', alignItems: 'center', justifyContent: 'center'  }}>
                    <Box sx={{ display: {xs: 'none', md: 'flex'}, position: 'absolute', right: 0, bottom: 0}}> 
                        <RightSquareLines />
                    </Box>
                    <Box sx={{ display: {xs: 'none', md: 'flex'}, position: 'absolute', left: 0, top: 0 }}> 
                        <LeftSquareLines />
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth: '65rem', flexDirection: 'column'}}>
                        <Box
                            sx={{
                                    textAlign: 'left',
                                    font: 'normal normal normal 40px/44px PT Serif',
                                    letterSpacing: '0px',
                                    color: '#2F2D2D',
                                    opacity: '1'
                                }}>
                            Avoid the most common pitfalls.
                        </Box>
                        <Box
                            sx={{
                                    textAlign: 'left',
                                    font: 'normal normal normal 18px/24px Roboto',
                                    letterSpacing: '0px',
                                    color: '#2F2D2D',
                                    opacity: '1'
                                }}>
                            The authors explain how to skip the painful part of the steep options learning curve, showing you how to avoid the most common pitfalls, and become a profitable trader quickly. You’ll find:.
                        </Box>
                        <List>
                            <HomeListItem
                                headLine={'A one-stop resource for everyone looking to become a pro-level trader, '}
                                subHeadLine={'including a primer on options without fancy math, engaging anecdotes and lots of invaluable institutional knowledge'}
                            />
                            <HomeListItem
                                headLine={'A review of the full options ecosystem, '}
                                subHeadLine={'and how to position yourself with the odds in your favor, to be a profitable player in this complex trading landscape'}
                            />
                            <HomeListItem
                                headLine={'A deep dive into the same strategies and techniques used by professional options traders, '}
                                subHeadLine={'without the need for finance degrees or hard-to-obtain experience'}
                            />
                            <HomeListItem
                                headLine={'A clear roadmap of how to create a practical and profitable trading endeavor '}
                                subHeadLine={'from the strategies and perspectives presented in the book'}
                            />
                        </List>
                    </Box>
                </Box>
                
                <Box sx={{ display: 'flex', position: 'relative', flexDirection: 'column', zIndex: 5  }}>
                    <BgFooterEdge sx={{ background: '#2F2D2D' }}/>
                    <Box sx={{ flexDirection: 'column', display: 'flex', padding: '5rem', width: '100%', background: '#2F2D2D', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                            <Box sx={{
                                textAlign: 'center',
                                font: {
                                    xs: 'normal normal normal 36px/40px PT Serif',
                                    md: 'normal normal normal 40px/44px PT Serif'
                                },
                                letterSpacing: '0px',
                                color: '#FFFFFF',
                                opacity: '1',
                                maxWidth: '75rem'
                            }}>A must-read book for investors looking to succeed in the options market without the expensive finance degrees or hard-to-obtain experience.</Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', background: '#2F2D2D', pl: '4rem', pr: '4rem', pb: '10rem', alignItems: 'center', justifyContent: 'center', flexDirection: { xs: 'column', md: 'row'}}}>
                        <ReviewItem
                            sx={{ mr: { xs: 0, md: '2rem' }, mb: { xs: '2rem', md: 0 }}}
                            review={'In Trading Options like the Pros, Michael and David show how to make good risk-adjusted returns by taking advantage of structural inefficiencies in the options market. If you trade options or hedge risk by using options, you will gain from learning all their insights.'}
                            reviewer={'David Einhorn'}
                            reviewerTitle={'Greenlight Capital'}
                        />
                        <ReviewItem
                            sx={{ mr: { xs: 0, md: '2rem' }, mb: { xs: '2rem', md: 0 }}}
                            quoteSx={{ paddingBottom: '8.5rem' }}
                            review={'Finally - A compact book that offers "civilian" investors the tools for selling options (convexity) properly!'}
                            reviewer={'Harley Bassman'}
                            reviewerTitle={'aka The Convexity Maven'}
                        />
                    </Box>
                    <BgFooterEdgeGrey />
                </Box>
                <BookCallToAction sx={{ mt: '-18rem', pt: '18rem', zIndex: 4 }}/>
            </Page>
        )
    }
}

export default Home