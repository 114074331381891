import { Box, BoxProps } from '@mui/material';
import React, { FunctionComponent } from 'react';
import PageContainer from '../../Page/PageContainer';

import { BaseProps } from '../../types';

export interface PageProps extends BaseProps{
    containerSx?: BoxProps['sx'],
}

export const Page: FunctionComponent<PageProps>  = ({ children, containerSx, sx }) => {
    return (
        <PageContainer sx={{ marginTop: '1.2rem', paddingBottom: '5rem', background: 'white', ...(containerSx ?? {})}}>
            <Box sx={{
                width: '100%',
                height: '100%',
                background: 'white',
                maxWidth: '150rem',
                position: 'relative',
                ...(sx ?? {})
            }}>
                {children}
            </Box>
        </PageContainer>
    )
}

export default Page