import React from 'react';
import Page from '../common/Page';
import { BaseProps } from '../../types';
import { BgOptionsTool } from '../assets';
import { Box, Button, TextField } from '@mui/material';
import BookCallToAction from '../common/BookCallToAction';
import OptionsTable from './OptionsTable';
import { getToolData } from '../../../api/tool';
import { ToolItem } from '../../../api/types';
import moment from 'moment';
  

interface State {
    minYearWinRate: number | string;
    maxYearMeanLoss: number | string;
    minimumSpreadWidth: number | string;
    filteredRows: ToolItem[];
    rows: ToolItem[];
    data_timestamp: string;
}

const DEFAULT_STATE = {
    minYearWinRate: 90,
    maxYearMeanLoss: -45,
    minimumSpreadWidth: 3,
}

class Tool extends React.Component <BaseProps, State> {
    state: State = {
        ...DEFAULT_STATE,
        filteredRows: [],
        rows: [],
        data_timestamp: '',
    }

    async componentDidMount(): Promise<void> {
        const rows = await getToolData();
        const startingTimestamp = rows.length ? rows[0].trade_date : '';
        const data_timestamp = rows.reduce((acc: string, row: ToolItem) => {
            if (!row.trade_date) return acc;

            return acc < row.trade_date ? acc : row.trade_date;
        }, startingTimestamp);

        const data_timestamp_formatted = moment(`${data_timestamp} 15:45`, 'YYYY-MM-DD HH:mm').format('MMMM Do, YYYY hh:mm a');
        this.setState({ rows, data_timestamp: data_timestamp_formatted }, this.applyFilters);
    }

    sanitizeNumericInput = (input: string): string => {
        // First, remove any character that is not a digit, dash, or period
        let sanitizedInput = input.replace(/[^0-9.-]/g, '');
    
        // Ensure the string starts with a valid character before proceeding
        if (sanitizedInput.length === 0) {
            return '';
        }
    
        // Remove any dashes that are not at the start
        sanitizedInput = `${sanitizedInput[0]}${sanitizedInput.substring(1).replace(/-/g, '')}`;
    
        // Split the string at the period to handle the decimal part
        const parts = sanitizedInput.split('.');
    
        // Join the first part with the rest (if any), removing additional periods
        sanitizedInput = parts.shift() + (parts.length > 0 ? '.' : '') + parts.join('');
    
        return sanitizedInput;
    };
    

    handleMinYearWinRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ minYearWinRate: this.sanitizeNumericInput(event.target.value) });
    }

    handleMaxYearMeanLossChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ maxYearMeanLoss: this.sanitizeNumericInput(event.target.value) });
    }

    handleMinimumSpreadWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ minimumSpreadWidth: this.sanitizeNumericInput(event.target.value) });
    }
    

    handleMinYearWinRateBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ minYearWinRate: !event.target.value.length || isNaN(Number(event.target.value)) ? 0 : parseFloat(event.target.value)});
    }

    handleMaxYearMeanLossBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ maxYearMeanLoss: !event.target.value.length || isNaN(Number(event.target.value)) ? 0 : parseFloat(event.target.value)});
    }

    handleMinimumSpreadWidthBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ minimumSpreadWidth: !event.target.value.length || isNaN(Number(event.target.value)) ? 0 : parseFloat(event.target.value)});
    }

    applyFilters = () => {
        const { minYearWinRate, maxYearMeanLoss, minimumSpreadWidth } = this.state;
        const filteredRows = this.state.rows.filter((row: ToolItem) => {
            const { prob_win, mean_loss_pl, moneyness_width } = row;

            return  prob_win >= (minYearWinRate as number) && mean_loss_pl >= (maxYearMeanLoss as number) && moneyness_width >= (minimumSpreadWidth as number);
        });

        this.setState({ filteredRows });
    }

    render() {
        const { minYearWinRate, maxYearMeanLoss, minimumSpreadWidth, filteredRows: rows, data_timestamp } = this.state;

        return (
            <Page>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
                        <BgOptionsTool sx={{ width: '100%', minHeight: { xs: '100rem', md: '85rem' }, objectFit: 'cover'}} />
                    </Box>
                    <Box sx={{ mt: '25rem', zIndex: 5, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', pl: { xs: '4rem', md: 'unset'}, pr: { xs: '4rem', md: 'unset'}  }}>
                        <Box sx={{
                                textAlign: 'center',
                                font: 'normal normal normal 48px/52px PT Serif',
                                letterSpacing: '0px',
                                color: '#2F2D2D',
                                opacity: '1'
                            }}>Option Screener
                        </Box>
                        <Box sx={{
                                mt: '2rem',
                                textAlign: 'center',
                                font: 'normal normal normal 18px/24px Roboto',
                                letterSpacing: '0px',
                                color: '#312F30',
                               opacity: '1',
                                maxWidth: '90rem'
                            }}>This is a generalized version of the option screener presented in the book.  The screener assumes 100bps of risk and is limited to put and call spreads on SPY, TLT, and GLD with 2 weeks to expiry (or closest to that).
                        </Box>
                        <Box sx={{
                                mt: '1rem',
                                textAlign: 'center',
                                font: 'normal normal normal 18px/24px Roboto',
                                letterSpacing: '0px',
                                color: '#312F30',
                               opacity: '1',
                                maxWidth: '85rem'
                            }}>Simply set your trailing 1 year minimum win rate (%), trailing 1 year maximum mean loss (bps), and minimum spread width (%) for a screen sorted by the expected return described in the book.
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: '5rem', '& .MuiInputLabel-root': { fontSize: '16px', fontWeight: 'bold' }, '& .MuiInputBase-input': { fontSize: '16px'} }}>
                        <TextField
                            label="Minimum 1 Year Win Rate (%)"
                            variant="outlined"
                            name="minYearWinRate"
                            value={minYearWinRate}
                            onChange={this.handleMinYearWinRateChange}
                            onBlur={this.handleMinYearWinRateBlur}
                            InputLabelProps={{
                              style: { 
                                top: '-6px',
                                fontWeight: 1000,
                              }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 'bold'
                                }
                            }}
                            sx={{
                                mr: '1rem', 
                                '& .MuiOutlinedInput-notchedOutline': {
                                    '& legend': {
                                        width: 0,
                                    }
                                }
                            }}
                        />
                        <TextField
                            label="Maximum 1 Year Mean Loss (bps)"
                            variant="outlined"
                            name="maxYearMeanLoss"
                            value={maxYearMeanLoss}
                            onChange={this.handleMaxYearMeanLossChange}
                            onBlur={this.handleMaxYearMeanLossBlur}
                            InputLabelProps={{
                              style: { 
                                top: '-6px',
                                fontWeight: 1000,
                              }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 'bold',
                                }
                            }}
                            sx={{
                                mr: '1rem', 
                                '& .MuiOutlinedInput-notchedOutline': {
                                    '& legend': {
                                        width: 0,
                                    }
                                }
                            }}
                        />
                        <TextField
                            label="Minimum Spread Width (%)"
                            variant="outlined"
                            name="minimumSpreadWidth"
                            value={minimumSpreadWidth}
                            onChange={this.handleMinimumSpreadWidthChange}
                            onBlur={this.handleMinimumSpreadWidthBlur}
                            InputLabelProps={{
                              style: { 
                                top: '-6px',
                                fontWeight: 1000,
                              }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 'bold'
                                }
                            }}
                            sx={{
                                mr: '1rem', 
                                '& .MuiOutlinedInput-notchedOutline': {
                                    '& legend': {
                                        width: 0,
                                    }
                                }
                            }}
                        />
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: '1rem' }}>
                                <Button variant="contained" sx={{ background: 'rgba(47, 45, 45, 1)', pt: '1rem', pb: '1rem' }} onClick={this.applyFilters}>Apply Filters</Button>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', maxWidth: '125rem', mt: '2rem' }} >
                            <Box sx={{ font: 'normal normal normal 16px/22px Roboto', letterSpacing: '0px', color: '#312F30', opacity: '1' }}>Data as of {data_timestamp}</Box>
                        </Box>
                        <OptionsTable items={rows} />
                    </Box>
                </Box>
                <BookCallToAction sx={{ mt: '50rem' }} />
            </Page>
        )
    }
}

export default Tool