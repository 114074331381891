import React, { FunctionComponent, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';
import { BaseProps } from '../../../components/types';
import OptionsRow from './OptionsRow';
import { ToolItem } from '../../../api/types';
interface Props extends BaseProps {
    items: ToolItem[];
}

const OptionsTable: FunctionComponent<Props> = ({ sx, items }: Props) => {
    const [sortBy, setSortBy] = useState<{ key: keyof ToolItem, order: 'asc' | 'desc'}>({ key: 'expected_return', order: 'desc' });


    const handleSort = (property: keyof ToolItem) => {
      if (sortBy.key === property) {
        setSortBy({ key: property, order: sortBy.order === 'asc' ? 'desc' : 'asc' });
      } else {
        setSortBy({ key: property, order: 'desc' });
      }
  };
    const sortedItems = [...items].sort((a: ToolItem, b: ToolItem) => {
        const { key, order } = sortBy;
        const isAsc = order === 'asc';

        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
            return isAsc ? (a[key] as number) - (b[key] as number) : (b[key] as number) - (a[key] as number);
        }
        
        return isAsc ? `${a[key]}`.localeCompare(`${b[key]}`) : `${b[key]}`.localeCompare(`${a[key]}`);
    });

    return (
        <TableContainer component={Paper} sx={{ maxWidth: '125rem', ...(sx ?? {}) }}>
            <Table aria-label="customized table" sx={{ '& th, td': { fontSize: '1.25rem'}}}>
                <TableHead sx={{ background: 'rgba(47, 45, 45, 1)', '& th': { color: 'white', '& .Mui-active': { color: 'white', '& .MuiTableSortLabel-icon': { color: 'white' }}, '& .MuiTableSortLabel-root': { color: 'white' } }}}>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                sx={{ ml: '3rem' }}
                                active={sortBy.key === 'Names'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('Names')}>
                                Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy.key === 'expected_return'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('expected_return')}>
                                Expected Return
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy.key === 'current_premium'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('current_premium')}>
                                Current Premium (bps)
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy.key === 'slippage_ratio'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('slippage_ratio')}>
                                Bid-Ask Slippage (%)
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy.key === 'prob_win'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('prob_win')}>
                                1 Year Win Rate (%)
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy.key === 'mean_loss_pl'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('mean_loss_pl')}>
                                1 Year Mean Loss (bps)
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortBy.key === 'moneyness_width'}
                                direction={sortBy.order ?? 'asc'}
                                onClick={() => handleSort('moneyness_width')}>
                                Spread Width (%) 
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                        <TableSortLabel
                            active={sortBy.key === 'contract'}
                            direction={sortBy.order ?? 'asc'}
                            onClick={() => handleSort('contract')}>
                            Contract
                        </TableSortLabel>
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedItems.map((item, index) => (
                        <OptionsRow key={item.Names} index={index} item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OptionsTable;
