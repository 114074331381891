import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios'

import App from './components/App';
import ThemeWrapper from './components/ThemeWrapper/ThemeWrapper';

//@ts-ignore
window.hsConversationsSettings = {
    loadImmediately: false
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json'


export const bootstrap = (async () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  return Promise.resolve(
    root.render(
      <React.StrictMode>
        <ThemeWrapper>
            <App />
        </ThemeWrapper>
      </React.StrictMode>
    )
  );
})();
