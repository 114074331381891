import React, { FunctionComponent } from 'react';
import { BaseProps } from '../../types';
import { Box } from '@mui/material';
import { BgFooterCTA, BgFooterEdge, BgFooterEdgeGrey, BookCover, BookCoverNoCTA } from '../assets';
import AvailableForPreorder from './AvailableForPreorder';

interface Props extends BaseProps {}
const BookCallToAction: FunctionComponent<Props> = ({ sx }: Props) => {
    return (
        <Box sx={{ display: 'flex', position: 'relative', ...(sx ?? {})}}>
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 2 }}>
              <BgFooterEdge sx={{ background: 'transparent', zIndex: 1 }}/>
            </Box>
            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0 }}>
              <BgFooterCTA sx={{ width: '100%', minHeight: { xs: '125rem', md: '85rem' }, objectFit: 'cover'}} />
            </Box>
            <Box sx={{ marginTop: { xs: '0rem', md: '15rem'}, flexDirection: { xs: 'column', md: 'row'}, display: 'flex', padding: '5rem', width: '100%', zIndex: 5 }}>
              <Box sx={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                  <Box
                      sx={{
                          textAlign: 'left',
                          font: 'normal normal normal 40px/44px PT Serif',
                          letterSpacing: '0px',
                          color: '#2F2D2D',
                          opacity: 1,
                          maxWidth: '62rem',
                          marginTop: '3rem'
                      }}>
                          Start trading options like the pros.
                  </Box>
                  <AvailableForPreorder sx={{ marginTop: '2rem', width: '100%' }} />
              </Box>
              <Box sx={{ display:'flex', flexDirection: 'column', flex: .8, alignItems: 'center', }}>
                  <BookCoverNoCTA sx={{ width: '100%', height: '100%', maxWidth: { xs: '50rem', md: undefined }, mt: { xs: '2rem', md: 0 } }} />
              </Box>
          </Box>
        </Box>
    )
}

export default BookCallToAction