import React from "react"


//@ts-ignore
import { withRouter, WithRouterProps } from '../common/withRouter';
import { Box } from '@mui/material';
import { BookLogo, BookLogoWhite } from '../Landing/assets';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface NavBarComponentProps extends WithRouterProps {
}
interface NavBarComponentState {
}
class NavBarComponent extends React.Component <NavBarComponentProps, NavBarComponentState> {

  constructor(props: NavBarComponentProps) {
    super(props)
  }
  

  render() {
    const { location } = this.props
    return(
      <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 5000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          ['@media print']: {
              display: 'none',
          },
          '& .navbar': {
            marginBottom: 0,
          }
      }}>
          <Box sx={{
            top: '0px',
            left: '0px',
            width: '100%',
            height: '1.2rem',
            background: '#2F2D2D 0% 0% no-repeat padding-box',
            opacity: 1,
          }}/>
          <Box component={Navbar} sx={{
            padding: '1rem 5rem', 
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            maxWidth: '150rem',
            '& .navbar-brand': {
                marginBottom: 0,
          } }}>
            <Navbar.Brand as={Link} to={'/'} href={'/'}>
              {location.pathname === '/authors' && <BookLogoWhite sx={{ height: '9rem', width: '9rem'}} /> }
              {location.pathname !== '/authors' && <BookLogo sx={{ height: '9rem', width: '9rem'}} /> }
            </Navbar.Brand>
            <Box 
              component={Nav} 
              sx={{ 
                display: 'flex',
                flexDirection: 'row',
                '& .nav-link': {
                  font: 'normal normal normal 16px/28px Roboto',
                  letterSpacing: '0px',
                  color: '#' + (location.pathname === '/authors' ? 'FFF' : '312F30') + ' !important',
                  textTransform: 'capitalize',
                  opacity: 1,
                  paddingLeft: 'unset',
                  paddingRight: 'unset',
                  marginLeft: '2rem',
                  marginRight: '2rem',
                  borderBottom: '3px solid #0000',
                },
                '& .nav-link.active': {
                  borderBottom: '3px solid #957027',
                },
              }}>
              <Nav.Link as={Link} to={'/'} href={'/'} active={location.pathname === '/'}>Book</Nav.Link>
              <Nav.Link as={Link} to={'/tool'} href={'/tool'} active={location.pathname === '/tool'}>Option Screener</Nav.Link>
              <Nav.Link as={Link} to={'/authors'} href={'/authors'} active={location.pathname === '/authors'}>Authors</Nav.Link>
            </Box>
        </Box>
      </Box>
    )
  }
}
export default withRouter(NavBarComponent)
